<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Company Name</label
              ><label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="companyname"
                rules="required"
              >
                <b-form-input
                  v-model="companyname"
                  placeholder="Enter Company Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Date </label><label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Date"
                rules="required"
              >
                <flat-pickr
                  v-model="date"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    defaultDate: new Date(),
                    maxDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Vendor Type</label
              ><label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Vender Type"
                rules="required"
              >
                <v-select
                  v-model="vendortype"
                  :options="vendortypeOption"
                  label="vendortype"
                  @input="getVendorCategory($event)"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Category</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
              <v-select
                v-model="category"
                :options="categoryOption"
                label="vendorcategory"
                placeholder="None"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address 1</label>
              <b-form-input v-model="address1" placeholder="Enter Address 1" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Address 2</label>
              <b-form-input v-model="address2" placeholder="Enter Address 2" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>At</label><label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="at"
                rules="required"
              >
                <b-form-input v-model="at" placeholder="Enter At" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Post Office</label>
              <b-form-input
                v-model="postoffice"
                placeholder="Enter Post Office"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Taluka</label>
              <b-form-input v-model="taluka" placeholder="Enter Taluka" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pin Code</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required|digits:6"
                name="Pin Code"
              >
                <b-form-input
                  v-model="pincode"
                  maxlength="6"
                  @input="clickPincode($event)"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  type="number"
                  placeholder="Enter Pin Code"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>District</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="City"
              >
                <v-select
                  v-model="city"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cityOption"
                  label="City"
                  @input="clickArea($event)"
                  placeholder="None"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Area</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Area"
              >
                <v-select
                  v-model="area"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="areaOption"
                  placeholder="None"
                  @input="handlePincode()"
                  label="PostOfficeName"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>State</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="State"
              >
                <v-select
                  v-model="state"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="None"
                  :options="stateOptions"
                  label="State"
                  @input="handleCity()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Contact Person</label
              ><label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Category"
                rules="required"
              >
                <b-form-input
                  v-model="contactperson"
                  placeholder="Enter Contact Person"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Mobile</label>
              <validation-provider
                #default="{ errors }"
                name="Mobile"
              >
                <b-form-input
                  v-model="mobile"
                  placeholder="Enter Mobile"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Email</label>
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="email"
              >
                <b-form-input v-model="email" placeholder="Enter Email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Fax no</label>
              <b-form-input v-model="faxno" placeholder="Enter Fax no" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Opening Balance</label>
              <b-form-input
                v-model="openingbalance"
                placeholder="Enter Opening Balance"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Cr/Dr</label>
              <v-select
                v-model="crdr"
                placeholder="None"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="['Cr', 'Dr']"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="group"
                rules="required"
              >
                <label>Group</label
                ><label style="color: red !important">*</label>
                <b-form-input v-model="group" placeholder="Enter Group" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Alias</label>
              <b-form-input v-model="alias" placeholder="Enter Alias" />
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-card-code title="Company Info" no-body>
              <b-col></b-col>
            </b-card-code>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>VAT No</label>
              <b-form-input v-model="vatno" placeholder="Enter VAT No" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>CST No</label>
              <b-form-input v-model="cstno" placeholder="Enter CST No" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pan No</label>
              <b-form-input
                v-model="panno"
                maxlength="10"
                @input="validatePanClick($event)"
                placeholder="Enter PAN No"
              />
              <small v-if="pannoValidate == false" class="text-danger"
                >Enter Valid PAN No</small
              >
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Service Tax No</label>
              <b-form-input
                v-model="servicetaxno"
                placeholder="Enter Service Tax No"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>GST No</label
              ><label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="GST"
                rules="required"
              >
                <b-form-input v-model="gstno" placeholder="Enter GST No" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Bank</label>
              <b-form-input v-model="bank" placeholder="Enter Bank" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Branch</label>
              <b-form-input v-model="branch" placeholder="Enter Branch" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>A/C No</label>
              <b-form-input v-model="accno" placeholder="Enter A/C no" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>IFSC Code</label>
              <b-form-input
                v-model="ifsccode"
                maxlength="11"
                @input="validateIFSCCodeClick($event)"
                placeholder="Enter IFSC Code"
              />
              <small v-if="ifsccodeValidate == false" class="text-danger"
                >Enter Valid IFSC Code</small
              >
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Payment Term (in days)</label>
              <b-form-input
                v-model="paymentterms"
                placeholder="Enter Payment"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>TDS?</label>
              <v-select
                v-model="tds"
                placeholder="None"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="['Yes', 'No']"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>TDS Group</label>
              <b-form-input v-model="tdsgroup" placeholder="Enter TDS Group" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>TDS %</label>
              <b-form-input v-model="tdspr" placeholder="Enter TDS %" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Cancle Cheque</label>
              <b-form-file
                v-model="documents.cancelcheque"
                placeholder="Select Cancle Cheque"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFileChange($event, 'cancelcheque', 'vendor')"
              />
            </b-form-group>
            <attachment :data="documents.cancelcheque" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Pancard Copy</label>
              <b-form-file
                v-model="documents.pancardcopy"
                placeholder="Select Pancard Copy"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFileChange($event, 'pancardcopy', 'vendor')"
              />
            </b-form-group>
            <attachment :data="documents.pancardcopy" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Visiting Card</label>
              <b-form-file
                v-model="documents.visitingcard"
                placeholder="Select Visiting Card"
                drop-placeholder="Drop file here..."
                accept="image/*"
                @input="handleFileChange($event, 'visitingcard', 'vendor')"
              />
            </b-form-group>
            <attachment :data="documents.visitingcard" />
          </b-col>
          <b-col md="12">
            <b-button
              class="mt-1"
              variant="primary"
              :disabled="flag"
              type="submit"
              @click.prevent="submitForm"
            >
              Submit
            </b-button>
            <b-button
              class="mt-1 ml-2"
              variant="primary"
              type="submit"
              @click="handleBack"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import flatPickr from "vue-flatpickr-component";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BTable,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from '@/components/axios';
import moment from "moment";
import Attachment from "../../../../components/Attechment.vue";

export default {
  components: {
    PinchScrollZoom,
    flatPickr,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BTable,
    Attachment,
  },
  data() {
    return {
      companyname: "",
      date: moment(new Date()).format("DD/MM/yyyy"),
      vendortype: "",
      category: "",
      address1: "",
      address2: "",
      at: "",
      postoffice: "",
      taluka: "",
      city: "",
      cityOption: [],
      state: "",
      pincode: "",
      contactperson: "",
      mobile: "",
      email: "",
      faxno: "",
      openingbalance: "",
      crdr: "",
      group: "",
      alias: "",
      vatno: "",
      cstno: "",
      panno: "",
      pannoValidate: true,
      servicetaxno: "",
      gstno: "",
      bank: "",
      branch: "",
      accno: "",
      ifsccode: "",
      ifsccodeValidate: true,
      paymentterms: "",
      tds: "",
      tdsgroup: "",
      tdspr: "",
      documents: {
        cancelcheque: "",
        pancardcopy: "",
        visitingcard: "",
      },
      ifEdit: false,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      flag: false,
      area: "",
      areaOption: [],
      StateData: "",
      fromState: "",
      stateOptions: [],
      vendortypeOption: [],
      categoryOption: [],
    };
  },
  async mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Vendor") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/master/accounting/vendor");
            }
          } else if (item.add !== 1) {
            this.$router.push("/master/accounting/vendor");
          }
        }
      });
    }
    this.getState();
    await this.getVendorType();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    async getEditValue() {
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getvendorById/${this.$route.params.id}`,
      }).then((response) => {
        this.getEditItems(response.data.data);
      });
    },
    async getVendorType() {
      await axios({
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/vendortype`,
      }).then((response) => {
        this.vendortypeOption = response.data.data.data;
      });
    },
    async getVendorCategory(e, id) {
      if (e) {
        let data = {
          vendortype: e.id,
        };
        await axios({
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/getvendorcategory`,
          data: JSON.stringify(data),
        }).then((response) => {
          this.categoryOption = response.data.data;
          if (id !== undefined) {
            this.categoryOption.map((item) => {
              if (item.id == id) {
                this.category = item;
              }
            });
          }
        });
      }
    },
    getEditItems(item) {
      item.map((item) => {
        this.documents.cancelcheque = item.cancelcheque;
        this.documents.pancardcopy = item.pancardcopy;
        this.documents.visitingcard = item.visitingcard;
        this.companyname = item.companyname;
        this.date = item.date;
        this.vendortype = item.vendortype;
        this.category = item.category;
        this.getVendorCategory({ id: item.vendortype }, item.category);

        this.address1 = item.address1;
        this.address2 = item.address2;
        this.at = item.at;
        this.postoffice = item.postoffice;
        this.taluka = item.taluka;
        this.city = item.district;
        this.state = item.state;
        this.pincode = item.pincode;
        this.contactperson = item.contactperson;
        this.mobile = item.mobile;
        this.email = item.email;
        this.faxno = item.faxno;
        this.openingbalance = item.openingbalance;
        this.crdr = item.crdr;
        this.group = item.group;
        this.alias = item.alias;
        this.vatno = item.vatno;
        this.cstno = item.cstno;
        this.panno = item.panno;
        this.servicetaxno = item.servicetaxno;
        this.gstno = item.gstno;
        this.bank = item.bank;
        this.branch = item.bankbranch;
        this.accno = item.accno;
        this.ifsccode = item.ifsccode;
        this.paymentterms = item.paymentterms;
        this.tds = item.tds;
        this.tdsgroup = item.tdsgroup;
        this.tdspr = item.tdspr;
        this.handleCity();
        this.handleArea();
        this.handleVendorType();
      });
      // this.clickPincode()
    },
    handleVendorType() {
      this.vendortypeOption.map((item) => {
        if (item.id == this.vendortype) {
          this.vendortype = item;
        }
      });
    },
    async handleArea() {
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getAreaByCity`,
        data: {
          City: this.city,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.areaOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    clickArea(e) {
      if (this.fromState == true) {
        this.handleArea();
      }

      //     this.areaOption = []
      //   this.StateData.map((item) => {
      //     if (item.City == e) {
      //       this.areaOption.push(item.PostOfficeName)
      //     }
      //   })
    },
    handlePincode() {
      if (this.fromState == true) {
        this.pincode = this.area.Pincode;
      }
    },
    async handleCity() {
      this.areaOption = [];
      this.cityOption = [];
      this.fromState = true;
      const reportauthority = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCityByState`,
        data: {
          State: this.state,
        },
      };
      await axios(reportauthority)
        .then((response) => {
          this.cityOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getState() {
      const reportauthority = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getState`,
      };
      await axios(reportauthority)
        .then((response) => {
          this.stateOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },

    async clickPincode() {
      this.area = "";
      this.city = "";
      this.state = "";
      this.cityOption = [];
      this.areaOption = [];
      const regex = new RegExp(/^[0-9]{6}$/);
      if (regex.test(this.pincode)) {
        this.fromState = false;
        const reportauthority = {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/pincode`,
          data: {
            Pincode: `${this.pincode}`,
          },
        };
        await axios(reportauthority)
          .then((response) => {
            this.cityOption = [];
            this.StateData = response.data.data;
            this.areaOption = response.data.data;
            this.state = response.data.data[0];
            const resArr = [];
            response.data.data.filter(function (item) {
              const i = resArr.findIndex((x) => x.City == item.City);
              if (i <= -1) {
                resArr.push(item);
              }
              return null;
            });
            this.cityOption = resArr;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
    validatePanClick(e) {
      const regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
      this.panno = this.panno.toUpperCase();
      if (regex.test(this.panno) == true) {
        this.pannoValidate = true;
      } else {
        this.pannoValidate = false;
      }
    },
    validateIFSCCodeClick(e) {
      const regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
      this.ifsccode = this.ifsccode.toUpperCase();
      if (regex.test(this.ifsccode) == true) {
        this.ifsccodeValidate = true;
      } else {
        this.ifsccodeValidate = false;
      }
    },
    submitForm(e) {
      const data = {
        cancelcheque: this.documents.cancelcheque,
        pancardcopy: this.documents.pancardcopy,
        visitingcard: this.documents.visitingcard,
        companyname: this.companyname,
        date: this.date,
        vendortype: this.vendortype.id,
        category: this.category.id,
        address1: this.address1,
        address2: this.address2,
        at: this.at,
        postoffice: this.postoffice,
        taluka: this.taluka,
        pincode: this.pincode,
        area: this.area.PostOfficeName,
        state: this.state.State,
        district: this.city.City,

        contactperson: this.contactperson,
        mobile: this.mobile,
        email: this.email,
        faxno: this.faxno,
        openingbalance: this.openingbalance,
        crdr: this.crdr,
        group: this.group,
        alias: this.alias,
        vatno: this.vatno,
        cstno: this.cstno,
        panno: this.panno,
        servicetaxno: this.servicetaxno,
        gstno: this.gstno,
        bank: this.bank,
        bankbranch: this.branch,
        accno: this.accno,
        ifsccode: this.ifsccode,
        paymentterms: this.paymentterms,
        tds: this.tds,
        tdsgroup: this.tdsgroup,
        tdspr: this.tdspr,
      };
      this.$refs.simpleRules.validate().then(async (success) => {
        if (!success && !this.pannoValidate) {
          this.check = success;
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the Required Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.flag = true;

          await axios({
            method: this.ifEdit ? "PUT" : "post",
            url: this.ifEdit
              ? `${this.baseApi}/vendor/${this.$route.params.id}`
              : `${this.baseApi}/vendor`,
            headers: {
              "content-type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.flag = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : "Update SuccessFully",
                icon: "success",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.$router.push("/master/accounting/vendor");
            })
            .catch((error) => {
              this.flag = false;
              console.log(error, "error");
            });
        }
      });
    },
    async handleFileChange(e, name, type) {
      this.flag = true;

      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.documents[name] =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          this.flag = false;
        })
        .catch((error) => {
          this.flag = false;
          console.log(error, "error");
        });
    },
    handleBack() {
      this.$router.push("/master/accounting/vendor");
    },
  },
};
</script>
